import {
  ActionIcon,
  Anchor,
  Box,
  Burger,
  Button,
  createStyles,
  Divider,
  Grid,
  Group,
  GroupProps,
  Header,
  MantineSize,
  Menu,
  Paper,
  Portal,
  ScrollArea,
  Switch,
  Text,
  Transition,
  UnstyledButton,
  useMantineColorScheme,
} from '@mantine/core';
import { NextLink } from '@mantine/next';
import { Currency } from '@prisma/client';
import {
  IconBarbell,
  IconBrush,
  IconChevronRight,
  IconClubs,
  IconMoneybag,
  IconPhotoUp,
  IconSearch,
  IconUpload,
  IconUserCircle,
  IconVideoPlus,
  IconWriting,
} from '@tabler/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Fragment,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AccountSwitcher } from '~/components/AppLayout/AccountSwitcher';
import { BrowsingModeMenu } from '~/components/BrowsingMode/BrowsingMode';
// import { CivitaiLinkPopover } from '~/components/CivitaiLink/CivitaiLinkPopover';
import { useAccountContext } from '~/components/CivitaiWrapped/AccountProvider';
import { useSystemCollections } from '~/components/Collections/collection.utils';
import { CurrencyIcon } from '~/components/Currency/CurrencyIcon';
import { LoginRedirect } from '~/components/LoginRedirect/LoginRedirect';
import { Logo } from '~/components/Logo/Logo';
import { UploadTracker } from '~/components/Resource/UploadTracker';
import { SupportButton } from '~/components/SupportButton/SupportButton';
import { UserAvatar } from '~/components/UserAvatar/UserAvatar';
import { useIsMobile } from '~/hooks/useIsMobile';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { constants } from '~/server/common/constants';
import { useAuth } from '~/store/auth.store';
import { CONSTANT_ROUTES, PREFIX_SD } from '~/utils/constants';
import { LoginRedirectReason } from '~/utils/login-helpers';
import { containerQuery } from '~/utils/mantine-css-helpers';
import { AutocompleteSearch } from '../AutocompleteSearch/AutocompleteSearch';
import Icon from '../Icon';
// import { openBuyBuzzModal } from '../Modals/BuyBuzzModal';
import { UserBuzz } from '../User/UserBuzz';

// const FeatureIntroductionModal = dynamic(() =>
//   import('~/components/FeatureIntroduction/FeatureIntroduction').then(
//     (m) => m.FeatureIntroductionModal
//   )
// );

const HEADER_HEIGHT = 70;

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    flexWrap: 'nowrap',
    paddingLeft: theme.spacing.xs * 1.6, // 16px
    paddingRight: theme.spacing.xs * 1.6, // 16px

    [containerQuery.smallerThan('md')]: {
      paddingLeft: theme.spacing.xs * 0.8, // 8px
      paddingRight: theme.spacing.xs * 0.8, // 8px
    },
  },

  burger: {
    display: 'flex',
    justifyContent: 'flex-end',
    [containerQuery.largerThan('md')]: {
      display: 'none',
    },
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,

    [containerQuery.largerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [containerQuery.smallerThan('md')]: {
      display: 'none',
    },
  },

  searchArea: {
    [containerQuery.smallerThan('md')]: {
      display: 'none',
    },
  },

  links: {
    display: 'flex',
    [containerQuery.smallerThan('md')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [containerQuery.smallerThan('md')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },

  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    borderRadius: theme.radius.xl,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },

    [containerQuery.smallerThan('md')]: {
      display: 'none',
    },
  },

  userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },

  mobileSearchWrapper: {
    height: '100%',
  },

  dNone: {
    display: 'none',
  },
}));

type MenuLink = {
  label: ReactNode;
  href: string;
  redirectReason?: LoginRedirectReason;
  visible?: boolean;
  as?: string;
  rel?: string;
};

function defaultRenderSearchComponent({ onSearchDone, isMobile, ref }: RenderSearchComponentProps) {
  if (isMobile) {
    return (
      <AutocompleteSearch
        variant="filled"
        onClear={onSearchDone}
        onSubmit={onSearchDone}
        rightSection={null}
        ref={ref}
      />
    );
  }

  return <AutocompleteSearch />;
}

export function AppHeader({
  renderSearchComponent = defaultRenderSearchComponent,
  fixed = true,
}: Props) {
  const { profile } = useAuth((state) => state);
  const { classes, cx, theme } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const router = useRouter();
  const features = useFeatureFlags();
  const isMobile = useIsMobile();
  const { logoutSDcloud } = useAccountContext();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [userSwitching, setUserSwitching] = useState(false);
  // const ref = useClickOutside(() => setBurgerOpened(false));
  const searchRef = useRef<HTMLInputElement>(null);

  const isMuted = !!profile ?? false;
  const {
    groupedCollections: {
      Article: bookmarkedArticlesCollection,
      Model: bookmarkedModelsCollection,
    },
  } = useSystemCollections();

  // const { data: creator } = trpc.user.getCreator.useQuery(
  //   { id: currentUser?.id as number },
  //   { enabled: !!currentUser }
  // );

  const mainActions = useMemo<MenuLink[]>(
    () => [
      {
        href: '/generate',
        visible: !isMuted,
        label: (
          <Group align="center" spacing="xs">
            <IconBrush stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            Generate images
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/posts/create',
        visible: !isMuted,
        redirectReason: 'post-images',
        label: (
          <Group align="center" spacing="xs">
            <IconPhotoUp stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            Post images
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/posts/create?video=true',
        visible: !isMuted,
        redirectReason: 'post-images',
        label: (
          <Group align="center" spacing="xs">
            <IconVideoPlus stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            Post videos
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/models/create',
        visible: !isMuted,
        redirectReason: 'upload-model',
        label: (
          <Group align="center" spacing="xs">
            <IconUpload stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            Upload a model
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/models/train',
        visible: !isMuted && features.imageTraining,
        redirectReason: 'train-model',
        label: (
          <Group align="center" spacing="xs">
            <IconBarbell stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            <Text span inline>
              Train a LoRA
            </Text>
            <CurrencyIcon currency={Currency.BUZZ} size={16} />
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/articles/create',
        visible: !isMuted,
        redirectReason: 'create-article',
        label: (
          <Group align="center" spacing="xs">
            <IconWriting stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            Write an article
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/bounties/create',
        visible: !isMuted && features.bounties,
        redirectReason: 'create-bounty',
        label: (
          <Group align="center" spacing="xs">
            <IconMoneybag stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            <Text>Create a bounty</Text>
            <CurrencyIcon currency={Currency.BUZZ} size={16} />
          </Group>
        ),
        rel: 'nofollow',
      },
      {
        href: '/clubs/create',
        visible: !isMuted && features.clubs,
        redirectReason: 'create-club',
        label: (
          <Group align="center" spacing="xs">
            <IconClubs stroke={1.5} color={theme.colors.blue[theme.fn.primaryShade()]} />
            <Text>Create a club</Text>
          </Group>
        ),
        rel: 'nofollow',
      },
    ],
    [features.bounties, features.imageTraining, features.clubs, isMuted, theme]
  );
  const links = useMemo<MenuLink[]>(
    () => [
      {
        href: CONSTANT_ROUTES.PROFILE,
        visible: !!profile,
        label: (
          <Group align="center" spacing="xs">
            <Icon iconName={colorScheme === 'dark' ? 'account' : 'account_light'} size="24" />
            My profile
          </Group>
        ),
      },
      {
        href: CONSTANT_ROUTES.ORDER,
        visible: !!profile,
        label: (
          <Group align="center" spacing="xs">
            <Icon iconName={colorScheme === 'dark' ? 'payment' : 'payment_light'} size="24" />
            Payment History
          </Group>
        ),
      },
      {
        href: CONSTANT_ROUTES.BILLING_INFO,
        visible: !!profile,
        label: (
          <Group align="center" spacing="xs">
            <Icon iconName={colorScheme === 'dark' ? 'creditCard' : 'creditCard_light'} size="24" />
            Billing Information
          </Group>
        ),
      },
      {
        href: CONSTANT_ROUTES.HISTORY,
        visible: !!profile,
        label: (
          <Group align="center" spacing="xs">
            <Icon iconName={colorScheme === 'dark' ? 'history' : 'history_light'} size="24" />
            History
          </Group>
        ),
      },
      {
        href: CONSTANT_ROUTES.AUTH,
        visible: !profile,
        label: (
          <Group align="center" spacing="xs">
            <IconUserCircle stroke={1.5} />
            Sign In/Sign up
          </Group>
        ),
        rel: 'nofollow',
      },
    ],
    [
      profile,
      features.imageTrainingResults,
      features.bounties,
      features.buzz,
      features.questions,
      features.clubs,
      features.vault,
      bookmarkedModelsCollection,
      bookmarkedArticlesCollection,
      router.asPath,
      colorScheme,
    ]
  );

  const burgerMenuItems = useMemo(
    () =>
      mainActions
        .concat([{ href: '', label: <Divider /> }, ...links])
        .filter(({ visible }) => visible !== false)
        .map((link, index) => {
          const item = link.href ? (
            <Link key={index} href={link.href} as={link.as} passHref>
              <Anchor
                variant="text"
                className={cx(classes.link, { [classes.linkActive]: router.asPath === link.href })}
                onClick={() => setBurgerOpened(false)}
                rel={link.rel}
              >
                {link.label}
              </Anchor>
            </Link>
          ) : (
            <Fragment key={`separator-${index}`}>{link.label}</Fragment>
          );

          return link.redirectReason ? (
            <LoginRedirect key={link.href} reason={link.redirectReason} returnUrl={link.href}>
              {item}
            </LoginRedirect>
          ) : (
            item
          );
        }),
    [classes, setBurgerOpened, cx, links, mainActions, router.asPath]
  );
  const userMenuItems = useMemo(
    () =>
      links
        .filter(({ visible }) => visible !== false)
        .map((link, index) =>
          link.href ? (
            <Menu.Item
              key={link.href}
              display="flex"
              component={NextLink}
              href={link.href}
              as={link.as}
              rel={link.rel}
            >
              {link.label}
            </Menu.Item>
          ) : (
            <Fragment key={`separator-${index}`}>{link.label}</Fragment>
          )
        ),
    [links]
  );
  const [showSearch, setShowSearch] = useState(false);
  const onSearchDone = () => setShowSearch(false);

  const handleCloseMenu = useCallback(() => {
    setUserSwitching(false);
    setBurgerOpened(false);
    setUserMenuOpened(false);
  }, [setBurgerOpened]);

  useEffect(() => {
    if (showSearch && searchRef.current) {
      searchRef.current.focus(); // Automatically focus input on mount
    }
  }, [showSearch]);

  // const BuzzMenuItem = useCallback(
  //   ({
  //     textSize = 'md',
  //     withAbbreviation = true,
  //     ...groupProps
  //   }: GroupProps & {
  //     textSize?: MantineSize;
  //     withAbbreviation?: boolean;
  //   }) => {
  //     if (!features.buzz) return null;
  //     if (!profile) return null;

  //     return (
  //       <Link href="/user/buzz-dashboard">
  //         <Group
  //           p="sm"
  //           position="apart"
  //           mx={-4}
  //           mb={4}
  //           sx={(theme) => ({
  //             backgroundColor:
  //               theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
  //             cursor: 'pointer',
  //           })}
  //           onClick={handleCloseMenu}
  //           noWrap
  //           {...groupProps}
  //         >
  //           <Group spacing={4} noWrap>
  //             <UserBuzz
  //               iconSize={16}
  //               textSize={textSize}
  //               withAbbreviation={withAbbreviation}
  //               withTooltip={withAbbreviation}
  //             />
  //           </Group>
  //           <Button
  //             variant="white"
  //             radius="xl"
  //             size="xs"
  //             px={12}
  //             onClick={(e) => {
  //               e.preventDefault();
  //               e.stopPropagation();
  //               openBuyBuzzModal({}, { fullScreen: isMobile });
  //             }}
  //             compact
  //           >
  //             Buy Buzz
  //           </Button>
  //         </Group>
  //       </Link>
  //     );
  //   },
  //   [profile, features.buzz, handleCloseMenu, isMobile]
  // );

  // const mobileCreateButton = !isMuted && (
  //   <GenerateButton
  //     variant="light"
  //     py={8}
  //     px={12}
  //     h="auto"
  //     radius="sm"
  //     mode="toggle"
  //     compact
  //     className="inline-block md:hidden"
  //     data-activity="create:navbar"
  //   />
  // );

  // const createMenu = !isMuted && (
  //   <Menu
  //     position="bottom"
  //     offset={5}
  //     withArrow
  //     trigger="hover"
  //     openDelay={400}
  //     zIndex={constants.imageGeneration.drawerZIndex + 2}
  //     withinPortal
  //   >
  //     <Menu.Target>
  //       {features.imageGeneration ? (
  //         <Group spacing={0} noWrap className="hide-mobile">
  //           <GenerateButton
  //             variant="light"
  //             py={8}
  //             pl={12}
  //             pr={4}
  //             h="auto"
  //             radius="sm"
  //             mode="toggle"
  //             // Quick hack to avoid svg from going over the button. cc: Justin 👀
  //             sx={() => ({ borderTopRightRadius: 0, borderBottomRightRadius: 0 })}
  //             compact
  //             data-activity="create:navbar"
  //           />
  //           <Button
  //             variant="light"
  //             py={8}
  //             px={4}
  //             h="auto"
  //             radius="sm"
  //             sx={() => ({ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 })}
  //           >
  //             <IconChevronDown stroke={2} size={20} />
  //           </Button>
  //         </Group>
  //       ) : (
  //         <Button
  //           className={cx(classes.links, 'hide-mobile')}
  //           variant="filled"
  //           color="green"
  //           size="xs"
  //           pl={5}
  //         >
  //           <IconPlus size={16} /> New
  //         </Button>
  //       )}
  //     </Menu.Target>
  //     <Menu.Dropdown>
  //       {mainActions
  //         .filter(({ visible }) => visible !== false)
  //         .map((link, index) => {
  //           const menuItem = (
  //             <Menu.Item
  //               key={!link.redirectReason ? index : undefined}
  //               component={NextLink}
  //               href={link.href}
  //               as={link.as}
  //               rel={link.rel}
  //             >
  //               {link.label}
  //             </Menu.Item>
  //           );

  //           return link.redirectReason ? (
  //             <LoginRedirect key={index} reason={link.redirectReason} returnUrl={link.href}>
  //               {menuItem}
  //             </LoginRedirect>
  //           ) : (
  //             menuItem
  //           );
  //         })}
  //     </Menu.Dropdown>
  //   </Menu>
  // );

  return (
    <Header height={HEADER_HEIGHT} fixed={fixed} zIndex={200}>
      {/* <Box className={cx(classes.mobileSearchWrapper, { [classes.dNone]: !showSearch })}>
        {renderSearchComponent({ onSearchDone, isMobile: true, ref: searchRef })}
      </Box> */}

      <Grid
        className={cx(classes.header, { [classes.dNone]: showSearch })}
        m={0}
        gutter="xs"
        align="center"
      >
        <Grid.Col span="auto" pl={0}>
          <Group spacing="xs" noWrap>
            <Anchor
              component={NextLink}
              href="/"
              variant="text"
              onClick={() => setBurgerOpened(false)}
            >
              <Logo />
            </Anchor>
            <SupportButton />
            {/* Disabled until next event */}
            {/* <EventButton /> */}
          </Group>
        </Grid.Col>
        {/* <Grid.Col span={6} md={5} className={classes.searchArea}>
          {renderSearchComponent({ onSearchDone, isMobile: false })}
        </Grid.Col> */}
        <Grid.Col span="auto" className={classes.links} sx={{ justifyContent: 'flex-end' }}>
          <Group spacing="md" align="center" noWrap>
            <Group spacing="sm" noWrap>
              {/* {mobileCreateButton}
              {createMenu} */}
              {/* {profile && (
                <>
                  <UploadTracker />
                  <CivitaiLinkPopover />
                </>
              )} */}
              {/* <BrowsingModeIcon />
              {currentUser && <NotificationBell />}
              {currentUser && features.chat && <ChatButton />}
              {currentUser?.isModerator && <ModerationNav />}
              {currentUser && <ImpersonateButton />} */}
            </Group>
            <Button
              component={NextLink}
              href={profile ? CONSTANT_ROUTES.WORKSPACE : CONSTANT_ROUTES.AUTH}
              rel="nofollow"
              variant="default"
            >
              Workspace
              <IconChevronRight size={16} />
            </Button>
            <Menu
              width={260}
              opened={userMenuOpened}
              position="bottom-end"
              transition="pop-top-right"
              zIndex={constants.imageGeneration.drawerZIndex + 1}
              // radius="lg"
              onClose={() => {
                setUserSwitching(false);
                setUserMenuOpened(false);
              }}
              withinPortal
            >
              <Menu.Target>
                {profile ? (
                  <UnstyledButton
                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                    onClick={() => setUserMenuOpened(true)}
                  >
                    <Group spacing={8} noWrap>
                      <UserAvatar user={profile} size="md" />
                      {features.buzz && profile && <UserBuzz pr="sm" />}
                    </Group>
                  </UnstyledButton>
                ) : (
                  <Burger
                    opened={userMenuOpened}
                    onClick={() => setUserMenuOpened(true)}
                    size="sm"
                  />
                )}
              </Menu.Target>

              <Menu.Dropdown>
                <ScrollArea.Autosize
                  maxHeight="calc(90vh - var(--mantine-header-height))"
                  styles={{ root: { margin: -4 }, viewport: { padding: 4 } }}
                  offsetScrollbars
                >
                  {userSwitching ? (
                    <AccountSwitcher setUserSwitching={setUserSwitching} close={handleCloseMenu} />
                  ) : (
                    <>
                      {/* {profile && (
                        <Menu.Item
                          onClick={() => setUserSwitching(true)}
                          closeMenuOnClick={false}
                          mb={4}
                        >
                          <Group w="100%" position="apart">
                            <UserAvatar user={profile} withUsername />
                            <IconChevronRight />
                          </Group>
                        </Menu.Item>
                      )}
                      <BuzzMenuItem withAbbreviation={false} /> */}
                      {userMenuItems}
                      <Divider my={4} />
                      <Menu.Item
                        closeMenuOnClick={false}
                        icon={
                          <Icon
                            iconName={colorScheme === 'dark' ? 'moon' : 'moon_light'}
                            size="24"
                          />
                        }
                        onClick={() => toggleColorScheme()}
                      >
                        <Group align="center" position="apart">
                          Dark mode
                          <Switch
                            checked={colorScheme === 'dark'}
                            sx={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Group>
                      </Menu.Item>

                      {profile ? (
                        <Menu.Item
                          icon={
                            <Icon
                              iconName={colorScheme === 'dark' ? 'logout' : 'logout_light'}
                              size="24"
                            />
                          }
                          onClick={() => logoutSDcloud()}
                        >
                          Logout
                        </Menu.Item>
                      ) : null}
                    </>
                  )}
                </ScrollArea.Autosize>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Grid.Col>
        <Grid.Col span="auto" className={classes.burger}>
          <Group spacing={4} noWrap>
            {/* {mobileCreateButton} */}
            {/* <ActionIcon onClick={() => setShowSearch(true)}>
              <IconSearch />
            </ActionIcon> */}
            {/* {currentUser && <CivitaiLinkPopover />}
            {currentUser && <NotificationBell />}
            {currentUser && features.chat && <ChatButton />} */}
            {/*{currentUser?.isModerator && <ModerationNav />}*/}
            {/* {currentUser && <ImpersonateButton />} */}
            <Burger
              opened={burgerOpened}
              onClick={() => setBurgerOpened(!burgerOpened)}
              size="sm"
            />
            <Transition transition="scale-y" duration={200} mounted={burgerOpened}>
              {(styles) => (
                <Portal target="#main">
                  <Paper
                    className={classes.dropdown}
                    withBorder
                    shadow="md"
                    style={{ ...styles, borderLeft: 0, borderRight: 0 }}
                    radius={0}
                    sx={{ zIndex: 1002 }}
                  // ref={ref}
                  >
                    {userSwitching ? (
                      // TODO maybe move this to account switcher
                      <ScrollArea.Autosize maxHeight={'calc(100dvh - 135px)'}>
                        <AccountSwitcher
                          inMenu={false}
                          setUserSwitching={setUserSwitching}
                          close={handleCloseMenu}
                        />
                      </ScrollArea.Autosize>
                    ) : (
                      <>
                        {/* Calculate maxHeight based off total viewport height minus header + footer + static menu options inside dropdown sizes */}
                        <ScrollArea.Autosize maxHeight={'calc(100dvh - 135px)'}>
                          {/* {!!profile && (
                            <Group
                              className={classes.link}
                              w="100%"
                              position="apart"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => setUserSwitching(true)}
                            >
                              <UserAvatar user={profile} withUsername />
                              <IconChevronRight />
                            </Group>
                          )} */}
                          {/* <BuzzMenuItem mx={0} mt={0} textSize="sm" withAbbreviation={false} /> */}
                          {burgerMenuItems}
                          {profile && (
                            <>
                              <Divider />
                              <Box px="md" pt="md">
                                <BrowsingModeMenu closeMenu={() => setBurgerOpened(false)} />
                              </Box>
                            </>
                          )}
                        </ScrollArea.Autosize>

                        <Group p="md" position="apart" grow>
                          <ActionIcon
                            variant="default"
                            onClick={() => toggleColorScheme()}
                            size="lg"
                            sx={(theme) => ({
                              color:
                                theme.colorScheme === 'dark'
                                  ? theme.colors.yellow[theme.fn.primaryShade()]
                                  : theme.colors.blue[theme.fn.primaryShade()],
                            })}
                          >
                            {colorScheme === 'dark' ? (
                              <Icon iconName="moon" size="20" />
                            ) : (
                              <Icon iconName="moon_light" size="20" />
                            )}
                          </ActionIcon>
                          {profile && (
                            <ActionIcon variant="default" onClick={() => logoutSDcloud()} size="lg">
                              <Icon
                                iconName={colorScheme === 'dark' ? 'logout' : 'logout_light'}
                                size="24"
                              />
                            </ActionIcon>
                          )}
                        </Group>
                      </>
                    )}
                  </Paper>
                </Portal>
              )}
            </Transition>
          </Group>
        </Grid.Col>
      </Grid>
    </Header>
  );
}

type Props = {
  renderSearchComponent?: (opts: RenderSearchComponentProps) => ReactElement;
  fixed?: boolean;
};
export type RenderSearchComponentProps = {
  onSearchDone?: () => void;
  isMobile: boolean;
  ref?: RefObject<HTMLInputElement>;
};
