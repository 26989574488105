export const LOCAL_STORAGE = {
  LANGUAGE: 'SM_Language',
  ACCESS_TOKEN: 'SM_ACCESS_TOKEN',
  REFRESH_TOKEN: 'SM_REFRESH_TOKEN',
  PAYMENT: 'PAYMENT',
  PATH_NAME: 'PATH_NAME',
  TITLE_CD: 'TITLE_CD',
  SOCIAL_PKCE_UUID: 'SOCIAL_PKCE_UUID',
  SOCIAL_PKCE_STATE: 'SOCIAL_PKCE_STATE',
  USAGE_TIME_SESSION: 'USAGE_TIME_SESSION',
  INFO_PAYMENT_SESSION: 'INFO_PAYMENT_SESSION',
  IS_FOCUS: 'IS_FOCUS',
  THEME_MODE: 'THEME_MODE',
};

export const PREFIX_SD = '/sdcloud';

export const STATIC_SLUG = {
  PRIVACY_POLICY: `${PREFIX_SD}/privacy-policy`,
  TERM_OF_USE: `${PREFIX_SD}/term-of-use`,
};

export const SESSION_STORAGE = {
  PROMPT: '__SD-PROMPT',
};

export const CONSTANT_LANGUAGE_LIST = ['vi', 'en'];

export type ConstantRoutesCodes = keyof typeof CONSTANT_ROUTES;
export type ConstantRoutesType = typeof CONSTANT_ROUTES;

export const CONSTANT_ROUTES = {
  PROFILE: `${PREFIX_SD}/profile`,
  ORDER: `${PREFIX_SD}/order`,
  PRICE: `${PREFIX_SD}/price`,
  // TIMER: `${PREFIX_SD}/timer`,
  HISTORY: `${PREFIX_SD}/history`,
  AUTH: `${PREFIX_SD}/authentication`,
  PAYMENT: `${PREFIX_SD}/payment`,
  PAYMENT_SUCCESS: `${PREFIX_SD}/payment-success`,
  PAYMENT_FAILED: `${PREFIX_SD}/payment-failed`,
  FORGOT_PASSWORD: `${PREFIX_SD}/forgot-password`,
  SOCIAL: `${PREFIX_SD}/social`,
  WORKSPACE: `${PREFIX_SD}/workspace`,
  BILLING_INFO: `${PREFIX_SD}/billing-info`,
  LOGIN_GOOGLE: `${PREFIX_SD}/login-google`,
  LOGIN_APPLE: `${PREFIX_SD}/login-apple`,
  LOGIN_FACEBOOK: `${PREFIX_SD}/login-facebook`,
  PROMPT: `${PREFIX_SD}/prompt`,
};

export const CONSTANT_ROUTES_EN: ConstantRoutesType = {
  PROFILE: `${PREFIX_SD}/profile`,
  ORDER: `${PREFIX_SD}/order`,
  PRICE: `${PREFIX_SD}/price`,
  // TIMER: `${PREFIX_SD}/timer`,
  HISTORY: `${PREFIX_SD}/history`,
  AUTH: `${PREFIX_SD}/authentication`,
  PAYMENT: `${PREFIX_SD}/payment`,
  PAYMENT_SUCCESS: `${PREFIX_SD}/payment-success`,
  PAYMENT_FAILED: `${PREFIX_SD}/payment-failed`,
  FORGOT_PASSWORD: `${PREFIX_SD}/forgot-password`,
  SOCIAL: `${PREFIX_SD}/social`,
  WORKSPACE: `${PREFIX_SD}/workspace`,
  BILLING_INFO: `${PREFIX_SD}/billing-info`,
  LOGIN_GOOGLE: `${PREFIX_SD}/login-google`,
  LOGIN_APPLE: `${PREFIX_SD}/login-apple`,
  LOGIN_FACEBOOK: `${PREFIX_SD}/login-facebook`,
  PROMPT: `${PREFIX_SD}/prompt`,
};

export const CONSTANT_TAB_AUTH = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
};

export type StatusConnectType = 'connecting' | 'connected' | 'disconnected';

export const RESULT_CODE_MOMO: { [key: number]: string } = {
  0: 'Thành công',
  9000: 'Giao dịch đã được xác nhận thành công',
  8000: 'Giao dịch đang ở trạng thái cần được người dùng xác nhận thanh toán lại',
  7000: 'Giao dịch đang được xử lý',
  7002: 'Giao dịch đang được xử lý bởi nhà cung cấp loại hình thanh toán',
  1000: 'Giao dịch đã được khởi tạo, chờ người dùng xác nhận thanh toán',
  11: 'Truy cập bị từ chối',
  12: 'Phiên bản API không được hỗ trợ cho yêu cầu này',
  13: 'Xác thực doanh nghiệp thất bại',
  20: 'Yêu cầu sai định dạng',
  22: 'Số tiền giao dịch không hợp lệ',
  40: 'RequestId bị trùng',
  41: 'OrderId bị trùng',
  42: 'OrderId không hợp lệ hoặc không được tìm thấy',
  43: 'Yêu cầu bị từ chối vì xung đột trong quá trình xử lý giao dịch',
  44: 'Giao dịch bị từ chối vì mã thanh toán không hợp lệ',
  1001: 'Giao dịch thanh toán thất bại do tài khoản người dùng không đủ tiền',
  1002: 'Giao dịch bị từ chối do nhà phát hành tài khoản thanh toán',
  1003: 'Giao dịch bị đã bị hủy',
  1004: 'Giao dịch thất bại do số tiền thanh toán vượt quá hạn mức thanh toán của người dùng',
  1005: 'Giao dịch thất bại do url hoặc QR code đã hết hạn',
  1006: 'Giao dịch thất bại do người dùng đã từ chối xác nhận thanh toán',
  1007: 'Giao dịch bị từ chối vì tài khoản không tồn tại hoặc đang ở trạng thái ngưng hoạt động',
  1026: 'Giao dịch bị hạn chế theo thể lệ chương trình khuyến mãi',
  1030: 'Đơn hàng thanh toán thất bại do thông tin không hợp lệ',
  1080: 'Giao dịch hoàn tiền bị từ chối. Giao dịch thanh toán ban đầu không được tìm thấy',
  1081: 'Giao dịch hoàn tiền bị từ chối. Giao dịch thanh toán ban đầu có thể đã được hoàn',
  2001: 'Giao dịch thất bại do sai thông tin liên kết',
  2007: 'Giao dịch thất bại do liên kết hiện đang bị tạm khóa',
  3001: 'Liên kết thất bại do người dùng từ chối xác nhận',
  3002: 'Liên kết bị từ chối do không thỏa quy tắc liên kết',
  3003: 'Hủy liên kết bị từ chối do đã vượt quá số lần hủy',
  3004: 'Liên kết này không thể hủy do có giao dịch đang chờ xử lý',
  4001: 'Giao dịch bị hạn chế do người dùng chưa hoàn tất xác thực tài khoản',
  4010: 'Quá trình xác minh OTP thất bại',
  4011: 'OTP chưa được gửi hoặc hết hạn',
  4100: 'Giao dịch thất bại do người dùng không đăng nhập thành công',
  4015: 'Quá trình xác minh 3DS thất bại',
  10: 'Hệ thống đang được bảo trì',
  99: 'Lỗi không xác định',
};

export const SOCIAL_LOGIN_HOST = {
  GOOGLE: 'https://accounts.google.com/o/oauth2/v2/auth',
  TWITTER: 'https://twitter.com/i/oauth2/authorize',
  LINKEDIN: 'https://www.linkedin.com/oauth/v2/authorization',
  MICROSOFT: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  FACEBOOK: 'https://www.facebook.com/v20.0/dialog/oauth',
  APPLE: 'https://appleid.apple.com/auth/authorize',
};
