import { LOCAL_STORAGE } from '~/utils/constants';

let accessToken = typeof window !== "undefined" ? window?.localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN) : null;
let refreshToken = typeof window !== "undefined" ? window?.localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN) : null;


if (typeof window !== "undefined") {
  /**
 * Listen for changes from other tabs
 */
  window?.addEventListener('storage', (event) => {
    if (event.key === LOCAL_STORAGE.ACCESS_TOKEN) {
      accessToken = event.newValue;
    }
  });
}

export const getAccessToken = (): string | null => accessToken;

export const setAccessToken = (token: string): void => {
  accessToken = token;
  if (typeof window !== "undefined") {
    window?.localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
  }
};
export const getRefreshToken = (): string | null => refreshToken;

export const setRefreshToken = (token: string): void => {
  refreshToken = token;
  if (typeof window !== "undefined") {
    window?.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, token);
  }
};

export const removeAccessToken = (): void => {
  accessToken = null;
  if (typeof window !== "undefined") {
    window?.localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
  }
};

export const removeRefreshAccessToken = (): void => {
  refreshToken = null;
  if (typeof window !== "undefined") {
    window?.localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
  }
};

export const setLocalStorage = (name: string, value: string) => {
  if (typeof window !== "undefined") {
    window?.localStorage.setItem(name, value);
  }
};

export const removeLocalStorage = (name: string) => {
  if (typeof window !== "undefined") {
    window?.localStorage.removeItem(name);
  }
};

export const getLocalStorage = (name: string): string | null => {
  if (typeof window !== "undefined") {
    return window?.localStorage.getItem(name);
  }
  return null;
}

export const clearLocalStorage = () => {
  if (typeof window !== "undefined") {
    window?.localStorage.clear();
  }
};
