import axiosInstance from '../common/instance';
import { GeneralDataTypes, HeaderFooterDataTypes, ErrorPageData } from './types';

export const getHeaderFooterService = async (): Promise<HeaderFooterDataTypes> => {
  const res = await axiosInstance.get('systems/header-footer');
  return res.data?.data;
};

export const getGeneralService = async (): Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data?.data;
};

export const getPageErrorService = async (): Promise<APIResponse<ErrorPageData>> => {
  const response = await axiosInstance.get('systems/errors-pages');
  return response.data;
};
