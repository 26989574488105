import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  getAccessToken,
  removeAccessToken,
  removeRefreshAccessToken,
  setLocalStorage,
} from './storage';
import { CONSTANT_ROUTES, LOCAL_STORAGE } from '~/utils/constants';

const axiosInstance = axios.create({
  baseURL: '/api/v1/',
});

axiosInstance.interceptors.request.use(
  ($config) => {
    if ($config.headers) {
      const token = getAccessToken();
      if (token) {
        $config.headers.Authorization = `Bearer ${token}`;
      }
      if ($config.method === 'get') {
        $config.params = {
          ...$config.params,
          locale: 'en',
        };
      }
      $config.headers['Content-Type'] = 'application/json';
      $config.headers.Accept = 'application/json';
    }
    return $config;
  },
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.status === 401) {
      setLocalStorage(LOCAL_STORAGE.PATH_NAME, window.location.pathname);
      removeAccessToken();
      removeRefreshAccessToken();
      if (!window.location.pathname.includes(CONSTANT_ROUTES.AUTH)) {
        window.location.href = CONSTANT_ROUTES.AUTH;
      }
    }
    return Promise.reject(
      error.response && error.response.status === 422 ? (error.response.data as any).errors : error
    );
  }
);

export default axiosInstance;
