import React from 'react';

import { mapModifiers } from 'utils/functions';

export const iconList = {
  logout: 'logout',
  profile: 'profile',
  account: 'account',
  account_light: 'account_light',
  payment: 'payment',
  payment_light: 'payment_light',
  creditCard: 'creditCard',
  creditCard_light: 'creditCard_light',
  history: 'history',
  history_light: 'history_light',
  logout_light: 'logout_light',
  moon: 'moon',
  moon_light: 'moon_light'
};

export type IconName = keyof typeof iconList;

export type IconSize = '6' | '8' | '9' | '12' | '14' | '16' | '17' | '20' | '22' | '24' | '32' | '40';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
